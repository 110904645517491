html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 4vh;
}

body {
  height: 100%;
  position: absolute;
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#root {
  display: flex;
  height: 100%;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.formLabel {
  font-size: 4vh;
  text-align: left;
  margin-top: 2vh;
}

.DialogOverlay {
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
}

button {
  font-size: 4vh;
  margin: 1vh 1vh 0 0;
  border-radius: 2vh;
  padding: 1vh;
  border-style: solid;
}

button:disabled,
button[disabled] {
  background-color: rgb(183, 179, 179);
}

.browse-button {
  background-color: #8127B9;
}

.playlist-button {
  background-color: #2135ef;
}

.DialogContent {
  min-width: 0;
  background: white;
  padding: 2vh;
  border-radius: 1vh;
  max-width: 80%;
}

.DialogContent h2 {
  font-size: 4vh;
  margin: 0;
  text-align: center;
}

input:invalid {
  border: dashed red;
  background: rgba(255,250,21,0.24)  ;
}

input:valid {
  border: solid lightgray;
}

input::placeholder {
  font-size: smaller;
  font-style: italic;
}

/*Toast styling*/
.Toast {
  font-size: 4vh;
  padding: 1vh;
  list-style-type: none;
  z-index: 10;
  position: absolute;
  background: #91ECB5;
  top: 0;
  left: 0;
  right: 0;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 1vh 1vh;
/*  Fade the toast in and out */
}

.ToastError {
  background: #CC4444;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.Toast[data-state='open'],
.Toast[data-state='open'] {
  animation: fadeIn 300ms ease-out;
}

.Toast[data-state='closed'],
.Toast[data-state='closed'] {
  animation: fadeOut 300ms ease-in;
}

.SongWithParam {
  font-size: 0.75em;
  height: 80%;
}

.left {
  text-align: left;
}

.playlist-kiosk {
  padding: 2vh 2vh 4vh 2vh;
  background-image: linear-gradient(to bottom right, #d5b8f2, #95f1dc);
  height: 100%;
}

.message {
  padding: 2vh;
}

table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
}

td {
  border: 1px solid grey;
}


table, th, td {
  padding: .5vh .5vh;
}

.button-div {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
